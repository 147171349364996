<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" has-modal-card width="960">
        <div class="modal-card" style="height: 100vh; width: 960px !important;">
          <header class="modal-card-head">
            <p class="modal-card-title">Insira a mensagem a ser enviada</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-tabs v-model="activeTab" type="is-boxed">
              <b-tab-item label="Número e horário de envio" icon="clock">
                <b-field label="Número de origem da mensagem">
                  <b-select placeholder="Selecione uma opção" v-model="selectedPhone" expanded>
                    <option v-for="whatsappSetting in getWhatsappSettings" :value="whatsappSetting"
                      :key="whatsappSetting.phone">{{ whatsappSetting.name ? whatsappSetting.name + ' - ' +
                        whatsappSetting.phone : whatsappSetting.phone }}</option>
                  </b-select>
                </b-field>
                <b-field label="Data e hora de envio (deixe vazio para enviar imediatamente)">
                  <b-datetimepicker rounded placeholder="Selecione uma data..." icon="calendar-today" locale="pt-BR"
                    v-model="schedule_date" :datepicker="{ showWeekNumber: true }" :timepicker="{ hourFormat: '24' }"
                    horizontal-time-picker>
                  </b-datetimepicker>
                </b-field>
                <b-message class="warning-message" type="is-danger">
                  A atualização do horário de funcionamento afeta os números, mensagens agendadas e departamentos ligados
                  a ele. Ou seja, caso o horário de funcionamento selecionado no campo <b>Horário permitido para envio de
                    mensagem</b> seja atualizado antes do envio da mensagem, a mesma obedecerá a nova configuração horário
                </b-message>
                <b-field label="Horário permitido para envio de mensagem">
                  <b-select placeholder="Selecione um horário" v-model="selectedBusinessHours" expanded required>
                    <option v-for="bh in businessHours" :value="bh.id" :key="bh.id">
                      {{ bh.description }}
                    </option>
                  </b-select>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Contatos" icon="account">
                <div class="field">
                  <b-checkbox v-model="sendToAll">Enviar para todos os contatos</b-checkbox>
                </div>
                <b-field label="Grupos de contatos para enviar a mensagem" v-if="!sendToAll">
                  <multiselect v-model="selectedContactsGroups" :disabled="sendToAll" :multiple="false"
                    :options="contactsGroups" label="name" track-by="id" select-label="" deselect-label="">
                    <template slot="placeholder">Grupos de contatos para enviar a mensagem</template>
                  </multiselect>
                </b-field>
                <b-field label="Enviar para contatos com as tags:" v-if="!sendToAll">
                  <multiselect v-model="sendToTags" :multiple="true" :options="getTags" label="title" track-by="id"
                    select-label="" deselect-label="">
                    <template slot="placeholder">Tags para enviar a mensagem</template>
                  </multiselect>
                </b-field>
                <b-field label="Não enviar para contatos com as tags:" v-if="!sendToAll">
                  <multiselect v-model="dontSendToTags" :multiple="true" :options="getTags" label="title" track-by="id"
                    select-label="" deselect-label="">
                    <template slot="placeholder">Tags para não enviar a mensagem</template>
                  </multiselect>
                </b-field>
                <b-field label="Contatos" v-if="!sendToAll">
                  <multiselect v-model="selectedContacts" :disabled="sendToAll || !!selectedContactsGroups"
                    :multiple="true" :options="getContacts" label="name" track-by="id" select-label="" deselect-label="">
                    <template slot="placeholder">Contatos para enviar a mensagem</template>
                  </multiselect>
                </b-field>
              </b-tab-item>
              <b-tab-item label="Mensagem" icon="text">
                <div v-if="selectedPhone && selectedPhone.api_type === W_API_TYPE.OFICIAL">
                  <b-field label="Selecione um template" grouped>
                    <b-select v-model="selectedTemplate" placeholder="" expanded>
                        <option v-for="template in templates" :key="template.id" :value="template">
                            {{ template.name }}
                        </option>
                    </b-select>
                  </b-field>
                  <b-field label="Preencha os valores " v-if="selectedTemplate && (headerVariables.length > 0 || bodyVariables.length > 0 || footerVariables.length > 0)">
                  <div class="column">
                      <b-field label="Cabeçalho da mensagem" v-if="selectedTemplate && headerVariables.length > 0">
                        <div v-for="component in headerVariables" :key="component.id">
                          <b-field :label="component.text">
                            <b-field v-if="component.type === 'DOCUMENT' || component.type === 'VIDEO' || component.type === 'IMAGE'" class="file is-primary" :class="{ 'has-name': !!files }" group-multiline>
                              <b-button class="button is-info" outlined @click="() => viewFile(component.id)">Visualizar arquivo exemplo</b-button>
                              <div class="control m-auto">
                                <span class="label mx-2 px-2">Ou</span>
                              </div>
                              <b-upload v-model="templateFile" class="file-label">
                                <span class="file-cta">
                                  <b-icon class="file-icon" icon="upload"></b-icon>
                                  <span class="file-label">Selecione um arquivo</span>
                                </span>
                                <span class="file-name" v-if="templateFile.name">
                                  {{ templateFile.name }}
                                </span>
                              </b-upload>
                            </b-field>
                            <b-input v-else v-model="header[Number(component.id) - 1]" type="textarea" expanded></b-input>
                          </b-field>
                        </div>
                      </b-field>
                      <b-field label="Corpo da mensagem" v-if="selectedTemplate && bodyVariables.length > 0" grouped>
                        <div v-for="component in bodyVariables" :key="component.id" class="column ">
                          <b-field :label="component.variable">
                            <b-input v-model="body[Number(component.id) - 1]" type="textarea" expanded></b-input>
                          </b-field>
                        </div>
                      </b-field>
                      <b-field label="Rodapé da mensagem" v-if="selectedTemplate && footerVariables.length > 0">
                        <div v-for="component in footerVariables" :key="component.id">
                          <b-field :label="component.variable">
                            <b-input v-model="footer[Number(component.id) - 1]" type="textarea" expanded></b-input>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </b-field>
                  <b-field label="Mensagem">
                    <editor api-key="no-api-key" v-model="message" :disabled="true" output-format="text" :init="{
                      height: 200,
                      menubar: false,
                      plugins: ['emoticons'],
                      toolbar: 'emoticons',
                      branding: false,
                      statusbar: false,
                    }" />
                  </b-field>

                  <p v-if="buttons && buttons.length" class="label" size="is-large"><b>Botões</b></p>

                  <b-field v-for="(button, index) in buttons" :key="button.id">
                    <b-field :label="button.text" grouped>
                      <b-select placeholder="Selecione uma opção" v-model="button.function" expanded>
                        <option value="PAD" key="PAD">Seguir o fluxo normal de atendimento</option>
                        <option value="PADI" key="PADI">Seguir o fluxo normal de atendimento (integração)</option>
                        <option value="REA" key="REA">Redirecionar para atendente</option>
                        <option value="RED" key="RED">Redirecionar para departamento</option>
                        <option value="TAG" key="TAG">Adicionar tag ao contato</option>
                        <option value="CLO" key="CLO">Encerrar atendimento</option>
                      </b-select>
                      <b-select v-if="button.function === 'RED' || button.function === 'REA'" v-on:change.native="setAttendants" placeholder="Selecione um departamento" v-model="button.departmentId" expanded>
                        <option v-for="department in filteredDepartments" :value="department.id" :key="department.name">
                          {{ department.name }}
                        </option>
                      </b-select>
                      <b-select v-if="button.function === 'REA'" @focus="setAttendantsButton(index)" placeholder="Selecione um atendente" v-model="button.attendantId" expanded>
                        <option v-for="attendant in attendants" :value="attendant.id" :key="attendant.name">
                          {{ attendant.name }}
                        </option>
                      </b-select>
                      <b-select v-if="button.function === 'TAG'" placeholder="Selecione uma tag" v-model="button.tagId" expanded>
                        <option v-for="tag in getTags" :value="tag.id" :key="tag.title">
                          {{ tag.title }}
                        </option>
                      </b-select>
                    </b-field>
                  </b-field>

                </div>
                <div v-else>
                  <b-message class="warning-message" type="is-warning">
                  As mensagens agendadas suportam variáveis que serão trocadas pelos dados do cliente: Utilize
                  <b>{nome}</b> e será trocado pelo nome do contato e utilize <b>{telefone}</b> e será trocado pelo número
                  de telefone do contato. Atenção que para a troca correta, o número já deve estar adicionado no sistema
                  como contato.
                </b-message>
                <b-field label="Mensagem">
                  <editor api-key="no-api-key" v-model="message" output-format="text" :init="{
                    height: 200,
                    menubar: false,
                    plugins: ['emoticons'],
                    toolbar: 'emoticons',
                    branding: false,
                    statusbar: false,
                    disabled: true
                  }" />
                </b-field>
                  <DragAndDrop :files_info="fileInfo"/>
                </div>
              </b-tab-item>
              <b-tab-item label="Resposta do contato" icon="message-cog">
                <b-message class="warning-message" type="is-danger">
                  <b>Se o contato estiver em atendimento </b>no momento do envio da mensagem, <b>o redirecionamento
                  não será feito, nem o menu enviado</b>, ou seja, ele seguirá no atendimento
                </b-message>
                <b-field label="O que fazer quando o cliente responder">
                  <b-select placeholder="Selecione uma opção" v-model="redirect_type" expanded>
                    <option value="NRE" key="NRE">Seguir o fluxo normal de atendimento</option>
                    <option value="REA" key="REA">Redirecionar para atendente</option>
                    <option value="RED" key="RED">Redirecionar para departamento</option>
                  </b-select>
                </b-field>
                <div class="row columns is-multiline is-vcentered">
                  <div class="column is-one-half">
                    <b-field label="Departamento">
                      <b-select
                        expanded
                        v-model="departmentId"
                        v-on:change.native="setAttendants"
                        :disabled="redirect_type === 'NRE'"
                        :required="redirect_type === 'NRE'"
                      >
                        <option v-for="department in filteredDepartments" :value="department.id" :key="department.name">
                          {{ department.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-one-half">
                    <b-field label="Atendente">
                      <b-select
                        expanded
                        v-model="attendantId"
                        :disabled="redirect_type === 'NRE' || redirect_type === 'RED'"
                        :required="redirect_type === 'NRE' || redirect_type === 'RED'"
                      >
                        <option v-for="attendant in attendants" :value="attendant.id" :key="attendant.name">
                          {{ attendant.name }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <b-field label="Intervalo limite para resposta redirecionar o contato (em minutos)">
                  <b-input v-model="response_timeout_minutes" :disabled="redirect_type === 'NRE'" min="1" type="number">
                  </b-input>
                </b-field>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button class="button is-primary" @click="addMessage">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import Editor from '@tinymce/tinymce-vue'
import { isLargeFile } from '../../utils/util'
import { API_TYPE, enumContentType, enumContentFormat } from '../../utils/constants'
import DragAndDrop from '../messages/DragAndDrop.vue'

export default {
  name: 'ModalInsertMessage',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    contacts: {
      type: Array,
      required: true
    },
    contactsGroups: {
      type: Array,
      required: true
    },
    whatsappSettings: {
      type: Array,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    businessHours: {
      type: Array,
      required: true
    },
    templates: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    allAttendants: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      message: '',
      schedule_date: undefined,
      selectedContacts: [],
      selectedContactsGroups: null,
      sendToAll: false,
      files: [],
      fileInfo: [],
      templateFile: {},
      selectedPhone: '',
      sendToTags: [],
      dontSendToTags: [],
      selectedBusinessHours: null,
      selectedTemplate: null,
      header: [],
      body: [],
      footer: [],
      headerVariables: [],
      bodyVariables: [],
      footerVariables: [],
      redirect_type: 'NRE',
      departmentId: null,
      attendantId: null,
      attendants: [],
      response_timeout_minutes: null,
      W_API_TYPE: {},
      filteredDepartments: [],
      buttons: [],
      activeTab: 0
    }
  },
  watch: {
    activeTab: function (val) {
      this.attendants = this.allAttendants
    },
    sendToAll: function (value) {
      this.selectedContacts = []
      if (value) {
        this.contacts.map((contact) => this.selectedContacts.push(contact))
      }
    },
    selectedContactsGroups: function (value) {
      if (value) {
        this.selectedContacts = []
      }
    },
    templateFile: function (file) {
      this.fileInfo = []
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.fileInfo.push({
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result.substr(reader.result.indexOf(',') + 1)
        })
      }
    },
    selectedTemplate (val) {
      if (val) {
        this.selectedTemplate.components = JSON.parse(this.selectedTemplate.json).components
        this.message = this.adaptMessage(this.selectedTemplate.components)
        if (this.selectedTemplate && this.selectedTemplate.components) {
          this.headerVariables = []
          this.bodyVariables = []
          this.footerVariables = []
          this.header = []
          this.body = []
          this.footer = []
          this.buttons = this.selectedTemplate.template_buttons.map(button => {
            return {
              id: button.id,
              text: button.button_id,
              attendantId: button.attendant_id,
              function: button.button_function,
              departmentId: button.department_id,
              tagId: button.tag_id
            }
          })
          for (let i = 0; i < this.selectedTemplate.components.length; i++) {
            const element = this.selectedTemplate.components[i]
            if (element.type === enumContentType.HEADER) {
              if (element.format === enumContentFormat.TEXT) {
                this.headerVariables = this.getVariables(element)
              }
              if (element.format === enumContentFormat.DOCUMENT) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: 'DOCUMENT',
                  variable: 'Documento'
                })
              }
              if (element.format === enumContentFormat.IMAGE) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: 'IMAGE',
                  variable: 'Imagem'
                })
              }
              if (element.format === enumContentFormat.VIDEO) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: 'VIDEO',
                  variable: 'Vídeo'
                })
              }
            } else if (element.type === enumContentType.BODY) {
              this.bodyVariables = this.getVariables(element)
            } else if (element.type === enumContentType.FOOTER) {
              this.footerVariables = this.getVariables(element)
            }
          }
        }
      }
    },
    header: function (newValue) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        let newMessage = ''
        this.selectedTemplate.components = JSON.parse(this.selectedTemplate.json).components
        for (let i = 0; i < this.selectedTemplate.components.length; i++) {
          const element = (this.selectedTemplate.components[i])
          newMessage = element.text
          if (element.type === enumContentType.HEADER && element.text) {
            for (let j = 0; j < this.bodyVariables.length; j++) {
              const variable = this.bodyVariables[j]
              newMessage = newMessage.replace(variable.variable, this.body[Number(variable.id) - 1] || variable.variable)
            }
            element.text = newMessage
          }
        }
        this.message = this.adaptMessage(this.selectedTemplate.components)
      }
    },
    body: function (newValue) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        let newMessage = ''
        this.selectedTemplate.components = JSON.parse(this.selectedTemplate.json).components
        for (let i = 0; i < this.selectedTemplate.components.length; i++) {
          const element = (this.selectedTemplate.components[i])
          newMessage = element.text
          if (element.type === enumContentType.BODY) {
            for (let j = 0; j < this.bodyVariables.length; j++) {
              const variable = this.bodyVariables[j]
              newMessage = newMessage.replace(variable.variable, this.body[Number(variable.id) - 1] || variable.variable)
            }
            element.text = newMessage
          }
        }
        this.message = this.adaptMessage(this.selectedTemplate.components)
      }
    },
    footer: function (newValue) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        let newMessage = ''
        this.selectedTemplate.components = JSON.parse(this.selectedTemplate.json).components
        for (let i = 0; i < this.selectedTemplate.components.length; i++) {
          const element = (this.selectedTemplate.components[i])
          newMessage = element.text
          if (element.type === enumContentType.FOOTER) {
            for (let j = 0; j < this.bodyVariables.length; j++) {
              const variable = this.bodyVariables[j]
              newMessage = newMessage.replace(variable.variable, this.body[Number(variable.id) - 1] || variable.variable)
            }
            element.text = newMessage
          }
        }
        this.message = this.adaptMessage(this.selectedTemplate.components)
      }
    },
    redirect_type (newValue) {
      switch (newValue) {
        case 'NRE':
          this.departmentId = null
          this.attendantId = null
          this.response_timeout_minutes = null
          break
        case 'RED':
          this.attendantId = null
          if ((!this.response_timeout_minutes || this.response_timeout_minutes < 0) && this.selectedPhone && this.selectedPhone !== '') {
            this.response_timeout_minutes = this.whatsappSettings.filter(el => el.phone === this.selectedPhone)[0]?.response_timeout_minutes ?? null
          }
          break
        case 'REA':
          if ((!this.response_timeout_minutes || this.response_timeout_minutes < 0) && this.selectedPhone && this.selectedPhone !== '') {
            this.response_timeout_minutes = this.whatsappSettings.filter(el => el.phone === this.selectedPhone)[0]?.response_timeout_minutes ?? null
          }
          break
        default:
          // do nothing
          break
      }
    },
    selectedPhone (newValue) {
      if ((!this.response_timeout_minutes || this.response_timeout_minutes < 0) && newValue && newValue !== '') {
        this.response_timeout_minutes = this.whatsappSettings.filter(el => el.phone === newValue)?.response_timeout_minutes ?? null
      }

      this.departmentId = null
      this.filteredDepartments = this.departments.filter(department => department.ww_phone === newValue || !department.ww_phone || !newValue)
    },
    buttons: {
      handler (val) {
        this.template.template_buttons = val
        this.buttons.map(b => {
          switch (b.function) {
            case 'REA':
              b.attendantId = b.attendantId || null
              b.departmentId = b.departmentId || null
              b.tagId = null
              break
            case 'RED':
              b.attendantId = null
              b.tagId = null
              break
            case 'TAG':
              b.attendantId = null
              b.departmentId = null
              break
            case 'CLO':
            case 'PAD':
            default:
              b.attendantId = null
              b.departmentId = null
              b.tagId = null
              break
          }
        })
      },
      deep: true
    }
  },
  components: {
    multiselect,
    editor: Editor,
    DragAndDrop
  },
  computed: {
    getWhatsappSettings () {
      if (this.templates && this.templates.length > 0) {
        return this.whatsappSettings
      } else {
        return this.whatsappSettings.filter(setting => setting.api_type === API_TYPE.WWEBJS)
      }
    },
    getContacts () {
      return this.contacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name} - (${c.phone})`
        return c
      })
    },
    getTags () {
      return this.tags.filter(tag => tag.enabled === true)
    }
  },
  created () {
    this.W_API_TYPE = API_TYPE
    this.attendants = this.allAttendants
  },
  methods: {
    deleteDropFile (index) {
      this.files.splice(index, 1)
    },
    viewFile (url) {
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'download.pdf'
      a.click()
    },
    adaptMessage (components) {
      let message = ''

      for (let i = 0; i < components.length; i++) {
        const component = components[i]
        if (component.type === enumContentType.HEADER) {
          if (component.format === enumContentType.TEXT) {
            message = this.getModifiedMessage(component.text)
          }
        }

        if (component.type === enumContentType.BODY) {
          if (message.length > 0) {
            message += '<br><br>'
          }
          message += this.getModifiedMessage(component.text)
        }

        if (component.type === enumContentType.FOOTER) {
          if (message.length > 0) {
            message += '<br><br>'
          }
          message += this.getModifiedMessage(component.text)
        }
      }

      return message
    },
    getModifiedMessage (message) {
      return message.replace(/<br>/g, '\n')
    },
    getVariables (element) {
      console.warn('getVariables', element)
      const regex = /{{\d+}}/g
      const variables = []
      let match
      while ((match = regex.exec(element.text))) {
        variables.push({
          id: match[0].replace(/{{|}}/g, ''),
          value: '',
          type: enumContentFormat.TEXT,
          variable: match[0],
          format: element.format,
          function: 'PAD'
        })
      }
      return variables
    },
    addMessage () {
      if (this.files && this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          if (isLargeFile(this.files[i])) {
            this.$buefy.dialog.alert({
              title: 'Tamanho do arquivo não suportado',
              message: 'O arquivo selecionado excede o limite suportado. Por favor, selecione um arquivo <b>menor que 20MB</b>',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })

            return
          }
        }
      }
      const validate = this.validate()
      if (validate.length === 0) {
        if (this.headerVariables.length > 0) {
          if (this.headerVariables[0].type !== enumContentFormat.TEXT) {
            this.header.push(this.headerVariables[0].value)
          }
        }
        this.$emit('addMessage', {
          message: this.message,
          schedule_date: this.schedule_date,
          contacts: this.selectedContacts,
          files_info: this.fileInfo,
          contact_group_id: this.selectedContactsGroups?.id,
          bypass_confirmation: true,
          phone_from: this.selectedPhone.phone,
          sendToTags: this.sendToTags,
          dontSendToTags: this.dontSendToTags,
          business_hours_id: this.selectedBusinessHours,
          template_id: this.selectedTemplate ? this.selectedTemplate.id : undefined,
          components: {
            header: this.header,
            body: this.body,
            footer: this.footer
          },
          redirect_type: this.redirect_type,
          department_id: this.departmentId,
          attendant_id: this.attendantId,
          response_timeout_minutes: this.response_timeout_minutes,
          buttons: this.buttons.map(button => {
            return {
              ...button,
              button_id: button.text,
              button_function: button.function,
              department_id: button.departmentId,
              attendant_id: button.attendantId,
              tag_id: button.tagId
            }
          })
        })
      } else {
        let msg = 'É necessário informar \n'
        for (let i = 0; i < validate.length; i++) {
          if (i === validate.length - 1) {
            msg = msg + validate[i] + ' para agendar a mensagem.'
          } else if (i === validate.length - 2) {
            msg = msg + validate[i] + ', e '
          } else {
            msg = msg + validate[i] + ', '
          }
        }
        this.$buefy.dialog.alert({
          title: 'Configuração incompleta',
          message: msg,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    closeModal () {
      this.$emit('closeModal')
    },
    validate () {
      const required = []
      if (!this.selectedPhone) required.push('o <b>número de origem</b>')
      if (!this.selectedBusinessHours) required.push('o <b>horário permitido para envio da mensagem</b>')
      if (this.selectedContacts.length === 0 &&
        !this.selectedContactsGroups &&
        this.sendToTags.length === 0 &&
        this.dontSendToTags.length === 0) {
        required.push('pelo menos um <b>contato</b>')
      }
      if (!this.message && this.fileInfo.length === 0) required.push('uma <b>mensagem ou arquivo</b>')
      if (this.redirect_type === 'RED' && !this.departmentId) required.push('um <b>departamento</b>')
      if (this.redirect_type === 'REA' && (!this.departmentId || !this.attendantId)) required.push('um <b>departamento</b> e um <b>atendente</b>')

      if ((this.redirect_type === 'REA' || this.redirect_type === 'RED') && (!this.response_timeout_minutes || this.response_timeout_minutes < 1)) required.push('um <b>intervalo limite para resposta redirecionar o contato</b> maior do que 1')

      return required
    },
    setAttendants () {
      this.attendants = this.allAttendants.filter(att => {
        if (att.departments.find(dpt => dpt.id === this.departmentId) || att.supervisor_all_departments) {
          return att
        }
      })
    },
    setAttendantsButton (index) {
      this.attendants = this.allAttendants.filter(att => {
        if (att.departments.find(dpt => dpt.id === this.buttons[index].departmentId) || att.supervisor_all_departments) {
          return att
        }
      })
    }
  },
  filters: {
    truncate (value, length) {
      if (!value) return ''
      if (value.length > length) {
        return value.substring(0, length) + '...'
      }
      return value
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.emoji-picker-container {
  white-space: pre-wrap;
}

.multiselect--active {
  z-index: 999;
}

.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
